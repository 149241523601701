import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import {sanitizeText, sanitizeParagraphs} from 'utils/html';
import Image from 'Components/Image';
import s from './Hero.module.scss';

const Hero = ({
    title,
    text,
    link,
    image,
    isLogo,
}) => {
    const sizes = [
        '(min-width: 1024px) 50vw',
        '(min-width: 768px) 720px',
        '(min-width: 500px) 468px',
        '100vw',
    ];

    const classes = classNames(
        [s['Hero']],
        {[s['Hero--Logo']]: isLogo},
    );

    return (
        <div className={classes}>
            <div className={s['Hero__Wrap']}>
                <div className={s['Hero__Columns']}>
                    <div className={s['Hero__Column']}>
                        <h1
                            className={s['Hero__Title']}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeText(title),
                            }}
                        />
                        <div
                            className={s['Hero__Text']}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeParagraphs(text),
                            }}
                        />
                        {!_.isEmpty(link) &&
                            <Link {...link} />
                        }
                    </div>
                    <div className={s['Hero__Column']}>
                        {!_.isEmpty(image) &&
                            <div className={s['Hero__Image']}>
                                {isLogo ? (
                                    <Image
                                        {...image}
                                        lazyLoad={false}
                                        priority={true}
                                    />
                                ) : (
                                    <Image
                                        {...image}
                                        sizes={sizes}
                                        lazyLoad={false}
                                        priority={true}
                                        useCover={true}
                                    />
                                )}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.object,
    image: PropTypes.object,
    isLogo: PropTypes.bool,
};

Hero.defaultProps = {
    title: '',
    text: '',
    link: {},
    image: {},
    isLogo: false,
};

const Link = ({url, title, target}) => (
    <a className={s['Hero__Link']} href={url} target={target}>{title}</a>
);

Link.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    target: PropTypes.string,
};

Link.defaultProps = {
    title: '',
    url: '',
    target: '',
};

export default Hero;