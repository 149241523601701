import React from 'react';
import PropTypes from 'prop-types';
import Base from 'Layouts/Base';
import Hero from 'Components/Hero';
import Modules from 'Components/Modules';
import s from './FrontPage.module.scss';

const FrontPage = ({hero, modules}) => {
    return (
        <div className={s['FrontPage']}>
            <Hero {...hero} />

            {modules &&
                <Modules {...modules} initialSize="Large" />
            }
        </div>
    );
};

FrontPage.propTypes = {
    hero: PropTypes.object,
    modules: PropTypes.object,
};

FrontPage.defaultProps = {
    hero: {},
    modules: {},
};

export default Base(FrontPage);